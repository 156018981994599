<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="source：" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD:" class="mb5">
                <group-select
                  v-model="filterForm.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select BD"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="contracts:" class="mb5">
                <el-select
                  v-model="filterForm.contracts"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in contracts" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="advertisers:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.advertisers"
                  placeholder="advertisers"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="legalAdv:" class="mb5">
                <el-select
                  v-model="filterForm.advertisersLegalName"
                  filterable
                  allow-create
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option
                    v-for="item in advLegalNameList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="receive(s):" class="mb5">
                <el-select
                  v-model="filterForm.receiveStatusList"
                  :multiple="true"
                  clearable
                  style="width: auto"
                >
                  <el-option
                    v-for="item in receiveStatusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="payment(A):" class="mb5">
                <el-select
                  v-model="filterForm.paymentList"
                  filterable
                  collapse-tags
                  placeholder="payment account"
                  class="w100"
                  multiple=""
                  clearable
                >
                  <el-option v-for="item in paymentList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="delay:" class="mb5">
                <el-input clearable v-model="filterForm.delay" placeholder="delay"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="receive(d):" class="mb5">
                <el-date-picker
                  v-model="filterForm.receiveRange"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="12" :sm="11" :md="11" :lg="11" :xl="11" align="left" class="mr10">
              <el-form-item label-width="0" class="mb5">
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >Actual Revenue received:[{{
                    sumInfo.totalActualReceive | numberToCurrency
                  }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >Billing Revenue: [{{ sumInfo.totalAmount | numberToCurrency }}]</span
                >
                <span :xs="4" :sm="3" :md="4" :lg="4" :xl="4" class="ml10 font-16 cor337ab7"
                  >percent: {{ (sumInfo.ratio * 100).toFixed(3) | numberToCurrency }}%</span
                >
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          stripe
          border
          height="780"
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          :indent="20"
          @sort-change="sortChange"
          :row-style="getRowStyle"
          row-key="id"
          :tree-props="{ children: 'childrenList' }"
        >
          <el-table-column
            label="Contract"
            prop="contract"
            align="left"
            width="120px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="BD" prop="business" align="center"></el-table-column>
          <el-table-column label="SourceId" prop="sourceId" align="center"></el-table-column>
          <el-table-column label="SourceName" prop="sourceName" align="center"></el-table-column>
          <el-table-column
            label="BD Group"
            prop="businessType"
            align="center"
            :key="40"
          ></el-table-column>
          <el-table-column label="Advertisers" prop="advertisers" align="center"></el-table-column>
          <el-table-column
            label="Advertiser Legal name"
            prop="advertisersLegalName"
            align="center"
          ></el-table-column>
          <el-table-column
            label="Cycle of Business"
            prop="businessCycle"
            align="center"
          ></el-table-column>
          <el-table-column label="Account for payment" prop="accountForPayment" align="center">
            <template slot-scope="scope"
              >{{ scope.row.accountForPayment }}
              <i
                class="el-icon-edit"
                v-if="scope.row.id"
                @click="viewAccountForPayment(scope.row.id, scope.row.accountForPayment)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            label="Net/Payment Period"
            prop="paymentCycle"
            align="center"
          ></el-table-column>
          <el-table-column label="Type" prop="paymentType" align="center"></el-table-column>
          <el-table-column
            label="Status of Received"
            align="center"
            prop="received"
          ></el-table-column>
          <el-table-column label="Date of Received" prop="receivedDate" align="center">
            <template slot-scope="scope">
              {{ formatDate(scope.row.receivedDate) }}
            </template>
          </el-table-column>
          <el-table-column label="Period" prop="financeCycle" align="center">
            <template slot-scope="scope">
              <span style="font-weight: bold;">{{ scope.row.financeCycle }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Actual Revenue received" prop="actualRevenue" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalActualReceive | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column
            label="Bank Received"
            prop="actualAmount"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.actualAmount | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Billing Revenue" prop="amount" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.amount | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Commission" prop="commission" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.commission | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Tax" prop="tax" align="center" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.tax | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Unreceived" prop="unreceived" align="center">
            <template slot-scope="scope">
              {{ scope.row.unreceived | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Invoce No" prop="invoice" align="center">
            <template slot-scope="scope">
              <span @click="openFile(scope.row)" class="cor337ab7">{{ scope.row.invoice }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Invoice Raising Date" prop="invoiceRaisingDate" align="center">
            <template slot-scope="scope">
              {{ formatDate(scope.row.invoiceRaisingDate) }}
            </template>
          </el-table-column>
          <el-table-column label="Date of Receipt" prop="receiptDay" align="center">
            <template slot-scope="scope">
              {{ formatDate(scope.row.receiptDay) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Payment Interval"
            prop="paymentInterval"
            align="center"
          ></el-table-column>
          <el-table-column label="Delay Period" prop="delayPeriod" align="center"></el-table-column>
          <el-table-column label="Delay" prop="delay" align="center"></el-table-column>
          <el-table-column
            label="Reason for Delay"
            prop="delayReason"
            align="center"
          ></el-table-column>
          <el-table-column label="Note" prop="note" align="center"></el-table-column>
          <el-table-column label="Action" align="center" fixed="right" width="90">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                v-if="scope.row.id && scope.row.childrenList == null"
                :disabled="accountRoles.indexOf('FinanceTeam') < 0"
                @click="openDialog(scope.row)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                v-if="!showParent(scope.row) && scope.row.id"
                :disabled="accountRoles.indexOf('FinanceTeam') < 0"
                @click="deleteFinance(scope.row.id)"
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                v-if="showParent(scope.row) && scope.row.id"
                :disabled="accountRoles.indexOf('FinanceTeam') < 0"
                @click="openAddDialog(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--  编辑模态框-->
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="40%">
          <el-form :model="editData" size="small" ref="editForm" label-width="auto" :rules="rules">
            <el-form-item label="receivedDate" prop="receivedDate">
              <el-date-picker
                v-model="editData.receivedDate"
                value-format="yyyy-MM-dd"
                placeholder="signTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Bank Received" prop="actualAmount">
              <el-input
                v-model="editData.actualAmount"
                placeholder="Please enter actualAmount"
                @change="updateCommission"
              ></el-input>
            </el-form-item>
            <el-form-item label="commission" prop="commission">
              <el-input
                v-model="editData.commission"
                placeholder="Please enter commission"
              ></el-input>
            </el-form-item>
            <el-form-item label="tax" prop="tax">
              <el-input v-model="editData.tax" placeholder="Please enter commission"></el-input>
            </el-form-item>
            <el-form-item label="billing revenue" prop="amount">
              <el-input disabled v-model="editData.amount" placeholder="billing revenue"></el-input>
            </el-form-item>
            <el-form-item label="delay reason" prop="delayReason">
              <el-input
                v-model="editData.delayReason"
                placeholder="Please enter delay reason"
              ></el-input>
            </el-form-item>
            <el-form-item label="note" prop="note">
              <el-input v-model="editData.note" placeholder="Please enter note"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="editForm('editForm')">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 新增模态框-->
        <el-dialog :title="addDialog.title" :visible.sync="addDialog.visible" width="40%">
          <el-form :model="addData" size="small" ref="addForm" label-width="auto" :rules="rules">
            <el-form-item label="receivedDate" prop="receivedDate">
              <el-date-picker
                v-model="addData.receivedDate"
                value-format="yyyy-MM-dd"
                placeholder="signTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Bank Received" prop="actualAmount">
              <el-input
                v-model="addData.actualAmount"
                placeholder="Please enter actualAmount"
              ></el-input>
            </el-form-item>
            <el-form-item label="commission" prop="commission">
              <el-input
                v-model="addData.commission"
                placeholder="Please enter commission"
              ></el-input>
            </el-form-item>
            <el-form-item label="tax" prop="tax">
              <el-input v-model="addData.tax" placeholder="Please enter commission"></el-input>
            </el-form-item>
            <el-form-item label="delay reason" prop="delayReason">
              <el-input
                v-model="addData.delayReason"
                placeholder="Please enter delay reason"
              ></el-input>
            </el-form-item>
            <el-form-item label="note" prop="note">
              <el-input v-model="addData.note" placeholder="Please enter note"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="addForm('addForm')">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="signStatus修改" :visible.sync="offerLinkType.visible" width="30%">
          <el-select
            v-model="offerLinkType.data.accountForPayment"
            placeholder="请选择"
            class="w100"
          >
            <el-option
              v-for="item in paymentList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <span slot="footer" class="dialog-footer">
            <el-button @click="offerLinkType.visible = false">取 消</el-button>
            <el-button
              type="primary"
              :loading="loading.submitBtn"
              @click="updateAccountForPayment()"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import finance from '../controllers/finance/finance';
  export default {
    name: 'finance',
    ...finance,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }
  .row {
    display: flex;
  }
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
